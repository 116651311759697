@import "../../styles/base";

.GameMoveListUI {
}

.GameMoveListUIControlSet {
  display: flex;
  align-items: baseline;
  + * {
    margin-top: 0.3em;
  }
  > * {
    flex: 1 1 50%;
    + * {
      margin-left: 0.3em;
    }
  }
  .GameMoveListUIControlSet {
    > * {
      flex: 1 1 auto;
    }
  }
  .BaseInput {
    width: 4em;
  }
  .BaseLabel {
    text-align: right;
  }
}

.GameMoveListUIMoveList {
  overflow: hidden;
  border-radius: 0.38em;
  background-color: color("foreground", 0.03);
  padding-left: 0;
  list-style: none;
  border: 1px solid color("foreground", 0.1);
}

.GameMoveListRangeControl {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

@import '../../styles/base';

.MoveEditor {
  h2 {
    font-family: font(genwan);
    .BaseInputContentEditable {
      line-height: 1.5;
      padding-top: .19em;
      border-bottom: 1px solid color('foreground', .2);
      &:focus {
        border-bottom-color: color('red');
      }
    }
  }
  &[data-army="red"] {
    h2, .MoveEditorPlayerMark {
      color: color('red');
    }
  }
}

.MoveEditorDisplayDefRenderer {
  margin-top: .62em;
}
@import "../../../styles/base";

.DesignCustomizer {
}

.DesignCustomizerTwoColumnTable {
  > tbody {
    > tr {
      > td {
        width: 50%;
        &:first-child {
          padding-right: 0.25em;
        }
        &:last-child {
          padding-right: 0.25em;
        }
      }
    }
  }
}

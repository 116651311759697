@import "../../styles/base";

.GameMoveListItem {
  appearance: none;
  background-color: color("white", 0.5);
  border: none;
  padding: 0.5em 0.75em;
  border-radius: 0.19em;
  font-weight: 500;
  letter-spacing: 0.1em;
  cursor: pointer;
  &:hover {
    background-color: color("white");
    filter: brightness(1.1);
  }
  &[data-army="red"] {
    color: color("red");
  }
  &[data-is-current="true"] {
    &[data-army="red"] {
      background-color: color("red");
      color: color("white");
    }
    &[data-army="black"] {
      background-color: color("inkBlue");
      color: color("white");
    }
  }
  &[data-is-opening-setup="true"] {
    [lang^="en"] & {
      letter-spacing: 0;
    }
  }
}

@import "../../styles/base";

.AppContainer {
  --StudioTitleBarHeight: 6.7rem;
}

.PasswordScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--vh);
}

.PasswordScreenInner {
  border: 1px solid color("foreground", 0.1);
  padding: 1em;
  width: 25rem;
  max-width: 80%;
}

@import "../../../styles/base";

.BaseToggle {
  display: flex;
  position: relative;
  transform: translateZ(0);
  user-select: none;
  [type="radio"],
  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  &.fullWidth {
    display: block;
    .BaseToggleInner {
      display: flex;
    }
  }
  &.BaseToggleToggle {
    .BaseToggleBox {
      width: 16px * 2;
      height: 16px;
    }
    .BaseToggleBoxMarker {
      border-radius: 5em;
      width: 14px;
      height: 14px;
      background-color: color("background");
    }
    &.true {
      .BaseToggleBoxMarker {
        right: 1px;
        background-color: color("white");
      }
    }
    &.false {
      .BaseToggleBoxMarker {
        right: 50%;
        // opacity: .5;
      }
    }
  }
  &.BaseToggleCheckbox,
  &.BaseToggleRadio {
    .BaseToggleBox {
      width: 16px;
      height: 16px;
    }
    .BaseToggleBoxMarker {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
    &.true {
      .BaseToggleBoxMarker {
        opacity: 1;
      }
    }
  }
  &.BaseToggleRadio {
    .BaseToggleBox {
      border-radius: 50%;
    }
  }
  &.true {
    .BaseToggleBox {
      background-color: color("primary", 0.9);
    }
    .BaseToggleBoxCheck {
      width: 66.67%;
      height: 66.67%;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.62;
  }
}
.BaseToggleInner {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0.38em;
  margin-left: -0.38em;
  margin-right: -0.38em;
  cursor: pointer;
  border-radius: 0.2em;
  // transition: background-color .1s;
  width: calc(100% + 0.62em);
  &:hover {
    background-color: color("primary", 0.1);
    .BaseToggleBox {
      filter: brightness(1.1);
    }
  }
  input:focus + & {
    background-color: color("primary", 0.15);
  }
  &.inverted {
    &:hover {
      background-color: color("white", 0.1);
    }
    input:focus + & {
      background-color: color("white", 0.15);
    }
  }
  &:active {
    .BaseToggleBox {
      filter: brightness(0.9);
    }
  }
  &.disabled {
    @include setPrimaryColor("gray");
  }
}
.BaseToggleLabel {
  display: block;
  flex: 1 1 auto;
  text-align: left;
  font-weight: 500;
  a {
    color: color("primary");
    font-weight: 700;
    @extend %underlinedLinks;
    .inverted & {
      color: inherit;
    }
  }
}
.BaseToggleBox {
  position: relative;
  box-sizing: content-box;
  display: block;
  flex: 0 0 auto;
  background-color: color("foreground", 0.2);
  border: 1px solid color("foreground", 0.1);
  border-radius: 0.2em;
  margin-right: 0.5em;
  // transition: .1s;
  transform: translateZ(0);
  .inverted & {
    background-color: color("white", 0.2);
    border: 1px solid color("white", 0.3);
  }
}
.BaseToggleBoxMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  // transition: .1s;
  position: absolute;
  top: 0.1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 85%;
  letter-spacing: normal;
  font-size: 1rem;
  // box-shadow: 0 1px 3px rgba(0,0,0,0.5);
}
.BaseToggleBoxCheck {
  width: 75%;
  height: 75%;
  color: contrastColor("primary");
}

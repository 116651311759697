@import "../../../styles/base";

.ShadedBlock {
  background-color: color("background", 0.7);
  border-radius: 0.5em;
  &.spaceChildren {
    .ShadedBlockInner {
      > * {
        + * {
          margin-top: 0.5em;
        }
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    color: color("primary");
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.6rem;
  }
  .BaseHeader {
    .BaseHeading {
      font-size: 1.8rem;
    }
  }
  &.inverted {
    background-color: color("white", 0.1);
    color: color("white");
    h1,
    h2,
    h3,
    h4 {
      color: inherit;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    a {
      font-weight: 700;
      @extend %underlinedLinks;
    }
  }
  code {
    background-color: transparent;
    padding: 0;
    color: inherit;
  }
  &.intenseColor {
    border: 1px solid color("primary");
    background-color: color("primary", 0.9);
    color: contrastColor("primary");
    h1,
    h2,
    h3,
    h4 {
      color: inherit;
    }
  }
  &.interactable {
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.95);
    }
  }
}

.ShadedBlockInner {
  background-color: color("primary", 0.1);
  padding: 1em;
  border: 1px solid color("primary", 0.2);
  border-radius: inherit;
  @media #{$only-phone} {
    font-size: 1.4rem;
  }
}

@import "../../styles/base";

.GameOpeningSetupEditor {
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
}

.GameOpeningSetupEditorPieceBox {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: color("ivory", 0.5);
  border-radius: 0.38em;
  > * {
    + * {
      margin-top: 0.5em;
    }
  }
  p {
    opacity: 0.5;
  }
}

.GameOpeningSetupEditorPieceBoxPieceList {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.25em;
}

.GameOpeningSetupEditorPieceBoxDropArea {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: color("red", 0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  border: 2px solid color("red", 0.2);
  padding: 1em;
  backdrop-filter: blur(1em);
  margin: 0;
  &:hover {
    background-color: color("red", 0.3);
    border: 2px solid color("red", 0.5);
  }
}

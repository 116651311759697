.u-phone-hidden {
  @media #{$only-phone} {
    display: none !important;
  }
}
.u-no-grid-hidden {
  .no-grid & {
    display: none !important;
  }
}
.u-nowrap {
  white-space: nowrap;
}
.u-no-print {
  @media print {
    display: none;
  }
}
.u-only-print {
  @media not print {
    display: none !important;
  }
}
.u-positive {
  color: color("positive");
}
.u-alert {
  color: color("alert");
}
.u-default-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Ubuntu",
    "Helvetica Neue", sans-serif !important;
}
.u-tabular-number {
  font-feature-settings: "tnum" 1;
}

.no-scroll {
  overflow: hidden;
}

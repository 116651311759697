@import "../../styles/base";

.GameMoveListRangeControl {
  display: flex;
}
.GameMoveListRangeControlCurrentLabel {
  flex: 0 0 auto;
  min-width: 2em;
}
.GameMoveListRangeControlTotalLabel {
  flex: 0 0 auto;
  min-width: 2em;
  text-align: right;
  font-weight: 600;
}
@mixin trackStyle {
  width: 100%;
  height: 0.5em;
  cursor: pointer;
  background: color("foreground", 0.05);
  border-radius: 3px;
  border: 1px color("foreground", 0.2) solid;
}
@mixin thumbStyle {
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: color("red");
  cursor: pointer;
  position: relative;
  top: -0.25em;
}
.GameMoveListRangeControlInput {
  flex: 2 2 auto;
  appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: transparent;
  &::-webkit-slider-runnable-track {
    @include trackStyle;
  }
  &::-moz-range-progress {
    background-color: color("red");
  }
  &::-ms-fill-lower {
    background-color: color("red");
  }
  &::-ms-track {
    @include trackStyle;
  }
  &::-moz-range-track {
    @include trackStyle;
  }
  &::-webkit-slider-thumb {
    appearance: none;
    @include thumbStyle;
  }
  &::-moz-range-thumb {
    @include thumbStyle;
  }
  &::-ms-thumb {
    @include thumbStyle;
  }
}

%label {
  font-weight: 600;
  display: block;
  font-family: font(heading);
  font-size: inherit;
  margin-bottom: 0.38em;
  color: inherit;
}

@mixin colorCodedStates {
  &.state-neutral {
    @include setPrimaryColor("neutral");
  }
  &.state-success,
  &.state-positive {
    @include setPrimaryColor("positive");
  }
  &.state-success {
    @include setPrimaryColor("positive");
  }
  &.state-attention,
  &.state-warning {
    @include setPrimaryColor("attention");
  }
  &.state-alert,
  &.state-error {
    @include setPrimaryColor("alert");
  }
  &.state-disabled {
    filter: grayscale(0.7);
    opacity: 0.6;
    pointer-events: none;
  }
  &[class*="state-"] {
    --InputBorderColor: #{color("primary")};
  }
}
%colorCodedStates {
  @include colorCodedStates();
}

%defaultInput {
  box-sizing: border-box;
  width: 100%;
  border: 0;
  box-shadow: none;
  padding: 0.19em 0.38em;
  height: 2em;
  // @media #{$tablet} {
  // height: 3em;
  // }
  color: inherit;
  font-weight: inherit;
  background-color: color("background", 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: color("foreground", 0.19);
  border-radius: 0.3em;
  text-align: inherit;
  // @media #{$tablet} {
  //   height: 2em;
  // }
  @media #{$only-phone} {
    font-size: 16px;
  }
  &[type="date"] {
    text-align: left;
  }
  .inverted & {
    border-color: color("white", 0.1);
    background-color: color("white", 0.1);
    &:hover {
      background-color: color("white", 0.06);
      border-color: color("white", 0.15);
    }
    &:focus {
      background-color: color("white", 0.1);
      border-color: color("white", 0.38);
    }
  }
  .inverted[class*="state-"] & {
    border-color: color("primary", 0.75);
    background-color: color("primary", 0.19);
    &:hover {
      background-color: color("primary", 0.06);
    }
    &:focus {
      background-color: color("primary", 0.1);
    }
  }
  .mouse & {
    &:hover {
      background-color: color("primary", 0.06);
    }
    &.disabled,
    &[disabled] {
      &:hover {
        background-color: color("background", 0.12);
      }
    }
  }
  &.disabled,
  &[disabled] {
    color: color("foreground", 0.38);
    filter: grayscale(0.9);
    opacity: 0.8;
  }
  &:focus {
    outline: none;
    background-color: color("primary", 0.1);
    border-color: color("primary", 0.5);
  }
  .invalid &,
  .inverted.invalid & {
    // color: color('alert');
    border-color: color("alert");
    input {
      background-color: color("alert", 0.15);
    }
  }
  .disabled &,
  [disabled] &,
  .invalid[disabled] & {
    background-color: color("foreground", 0.05);
    color: color("foreground", 0.6);
    cursor: not-allowed;
  }
  .inverted.disabled &,
  .inverted[disabled] &,
  .inverted.invalid[disabled] & {
    background-color: color("white", 0.05);
    color: color("white", 0.6);
    backdrop-filter: none;
  }
  &::placeholder {
    font-style: italic;
    color: inherit;
    opacity: 0.5;
  }
}

%paddable {
  &.padded {
    padding-left: size(md);
    padding-right: size(md);
  }
  &.padded-all {
    padding-top: size(md);
    padding-bottom: size(md);
    + &.padded-all {
      padding-top: 0;
    }
  }
}

%underlinedLinks {
  text-decoration: none;
  background-image: linear-gradient(to top, currentColor, currentColor);
  background-size: 100% 0.62px;
  background-position: left 0 bottom 0.09em;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    background-size: 100% 1px;
  }
}

%asDarkTheme {
  @include setBackgroundColor("dark");
  @include setForegroundColor("white");
}
// %asMediumGrayTheme {
//   @include setBackgroundColor('median');
//   @include setForegroundColor('white');
// }
%asPrimaryTheme {
  @include setBackgroundColor("primary");
  @include setForegroundColor("white");
}

%hideScrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; // IE 10+
  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    display: none;
    background: transparent;
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
  }
  ::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    display: none;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    display: none;
    background: transparent;
  }
}

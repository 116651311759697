$z-indexes: (".StudioTitleBar");
$reversed-z-indexes: reverse($z-indexes);

@function z($name) {
  @if index($z-indexes, $name) {
    // @return (length($z-indexes) - index($z-indexes, $name)) + 10000 unquote('!important');
    @return (length($z-indexes) - index($z-indexes, $name)) + 10000;
  } @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}

@each $layer in $reversed-z-indexes {
  #{$layer} {
    z-index: z($layer);
  }
}

// then put overriding rules below
// usually overriding ruels are for responsive purposes

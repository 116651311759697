// @import './styles/base/index';
@import "./styles/index";

.App {
  --BorderColor: #{color("brown", 0.3)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

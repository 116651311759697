.BoardDesignCustomizer {
  tbody {
    th {
      text-align: right;
    }
    th,
    td {
      padding-bottom: 0.5em;
    }
    td {
      width: 30%;
      + th {
        padding-left: 0.5em;
      }
    }
  }
}

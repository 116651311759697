@import '../../../styles/base';

.BoardInsetEditor {
  border: 1px solid color('foreground', .2);
  padding: .25em;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  grid-template-areas: '. top .' 'left center right' ' . bottom .';
  border-radius: .2em;
  .BaseInput {
    // width: 4.2em;
  }
  input {
    height: 2em;
  }
  [data-name="top"] {
    grid-area: top;
  }
  [data-name="left"] {
    grid-area: left;
  }
  [data-name="right"] {
    grid-area: right;
  }
  [data-name="bottom"] {
    grid-area: bottom;
  }
}
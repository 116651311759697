@import "../../styles/base";

.GameMoveListRound {
  display: flex;
  align-items: baseline;
  &:nth-of-type(even) {
    background-color: color("white", 0.5);
  }
  &.--current {
    background-color: color("ivory");
    .GameMoveListRoundNumber {
      color: color("red");
    }
  }
}
.GameMoveListRoundNumber {
  background-color: color("ivory", 0.2);
  color: color("foreground", 0.5);
  width: 3em;
  text-align: right;
  padding: 0.5em;
  font-weight: 600;
}
.GameMoveListRoundMoves {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
}

@import "../../styles/base";

.StudioSidebarUIPanel {
}

.StudioSidebarUIPanelHeader {
  padding: 0.5em 1em;
  background-color: color("black", 0.05);
  h4 {
    color: color("primary");
    font-size: inherit;
  }
}

.StudioSidebarUIPanelContent {
  padding: 1em;
  table {
    width: 100%;
    vertical-align: baseline;
    border-collapse: collapse;
  }
  th,
  td {
    vertical-align: middle;
  }
  th {
    font-weight: 400;
    white-space: nowrap;
    padding-right: 0.5em;
    [lang^="en"] & {
      font-weight: 500;
    }
  }
  thead {
    th {
      text-align: left;
    }
  }
  tbody {
    tr {
      &:last-child {
        th,
        td {
          padding-bottom: 0.5em;
        }
      }
    }
    th {
      text-align: right;
    }
  }
}

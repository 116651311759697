$whitepointHsl: (33, 0%, 100%);
$blackpointHsl: (216, 0%, 0%);
$inkBlueHsl: (216, 37%, 20%);

$colors: (
  "red": (
    hsl: (
      11,
      75%,
      57%,
    ),
    contrast: $whitepointHsl,
  ),
  "orange": (
    hsl: (
      22,
      68%,
      61%,
    ),
    contrast: $inkBlueHsl,
  ),
  "lightBlue": (
    hsl: (
      176,
      38%,
      80%,
    ),
    contrast: $inkBlueHsl,
  ),
  "gold": (
    hsl: (
      47,
      46%,
      65%,
    ),
    contrast: $inkBlueHsl,
  ),
  "teal": (
    hsl: (
      169,
      64%,
      37%,
    ),
    contrast: $whitepointHsl,
  ),
  "brown": (
    hsl: (
      22,
      24%,
      66%,
    ),
    contrast: $whitepointHsl,
  ),
  "inkBlue": (
    hsl: $inkBlueHsl,
    contrast: $whitepointHsl,
  ),
  "lavender": (
    hsl: (
      281,
      20%,
      62%,
    ),
    contrast: $whitepointHsl,
  ),
  "white": (
    hsl: $whitepointHsl,
    contrast: $inkBlueHsl,
  ),
  "light": (
    hsl: (
      32,
      56%,
      98%,
    ),
    contrast: $inkBlueHsl,
  ),
  "ivory": (
    hsl: (
      32,
      43%,
      91%,
    ),
    contrast: $inkBlueHsl,
  ),
  "gray": (
    hsl: (
      191,
      11%,
      68%,
    ),
    contrast: $whitepointHsl,
  ),
  "slate": (
    hsl: (
      209,
      13%,
      34%,
    ),
    contrast: $whitepointHsl,
  ),
  "black": (
    // default darkest
    hsl: $blackpointHsl,
    contrast: $whitepointHsl,
  ),
) !default;

$contextColors: (
  "foreground": "black",
  "background": "white",
  "primary": "red",
  "secondary": "gold",
  "alert": "red",
  "attention": "orange",
  "neutral": "gold",
  "positive": "teal",
);

/** disable sass's default hsl to hex conversion */
// @function hsl($h, $s, $l) {
//   @return unquote('hsl(#{$h}, #{$s}, #{$l})');
// }
// @function hsla($h, $s, $l, $a) {
//   @return unquote('hsla(#{$h}, #{$s}, #{$l}, #{$a})');
// }

@function staticColor($colorName: a, $alpha: 1, $lx: 0, $sx: null) {
  $color: map-get($colors, $colorName);
  $hsl: map-get($color, hsl);
  @if ($lx == 0 and $sx == null) {
    $lx: 1 + $lx;
    @if $sx == null {
      $sx: abs($lx);
    }
    @if ($alpha == 1) {
      @return hsl(nth($hsl, 1), nth($hsl, 2), nth($hsl, 3));
    } @else {
      @return hsla(nth($hsl, 1), nth($hsl, 2), nth($hsl, 3), $alpha);
    }
  } @else {
    $lx: 1 + $lx;
    @if $sx == null {
      $sx: abs($lx);
    }
    $sx: 1 + $sx;
    @return hsla(nth($hsl, 1), nth($hsl, 2) * $sx, nth($hsl, 3) * $lx, $alpha);
  }
}
@function color($colorName: a, $alpha: 1, $lx: 0, $sx: null) {
  @if (map-get($colors, $colorName)) {
    @return staticColor($colorName, $alpha, $lx, $sx);
  }
  @if type-of($lx) == "number" {
    $lx: 1 + $lx;
  }
  @if type-of($sx) == "number" {
    $sx: 1 + $sx;
  }
  @if $sx == null {
    @if type-of($lx) == "number" {
      $sx: abs($lx);
    } @else {
      $sx: $lx;
    }
  }
  @if ($lx == 1 & $sx == 1) {
    @return unquote("hsla(") var(--#{$namespace}-c-#{"" + $colorName}-hsl),
      $alpha unquote(")");
  } @else {
    @return hsla(
      var(--#{$namespace}-c-#{"" + $colorName}-h),
      calc(var(--#{$namespace}-c-#{"" + $colorName}-s) *#{$sx}),
      calc(var(--#{$namespace}-c-#{"" + $colorName}-l) *#{$lx}),
      $alpha
    );
  }
}
@function contrastColor($colorName: a, $alpha: 1, $lx: 0, $sx: null) {
  $lx: 1 + $lx;
  @if $sx == null {
    $sx: abs($lx);
  }
  @if $lx == 1 {
    @return unquote("hsla(") var(--#{$namespace}-c-#{"" + $colorName}_hsl),
      $alpha unquote(")");
  } @else {
    @return hsla(
      var(--#{$namespace}-c-#{"" + $colorName}_h),
      calc(var(--#{$namespace}-c-#{"" + $colorName}_s) *#{$sx}),
      calc(var(--#{$namespace}-c-#{"" + $colorName}_l) *#{$lx}),
      $alpha
    );
  }
}

@mixin setContextColor($contextColorName, $colorName) {
  --#{$namespace}-c-#{'' + $contextColorName}-h: var(
    --#{$namespace}-c-#{"" + $colorName}-h
  );
  --#{$namespace}-c-#{'' + $contextColorName}-s: var(
    --#{$namespace}-c-#{"" + $colorName}-s
  );
  --#{$namespace}-c-#{'' + $contextColorName}-l: var(
    --#{$namespace}-c-#{"" + $colorName}-l
  );
  --#{$namespace}-c-#{'' + $contextColorName}-hsl: var(
    --#{$namespace}-c-#{"" + $colorName}-hsl
  );
  --#{$namespace}-c-#{'' + $contextColorName}_h: var(
    --#{$namespace}-c-#{"" + $colorName}_h
  );
  --#{$namespace}-c-#{'' + $contextColorName}_s: var(
    --#{$namespace}-c-#{"" + $colorName}_s
  );
  --#{$namespace}-c-#{'' + $contextColorName}_l: var(
    --#{$namespace}-c-#{"" + $colorName}_l
  );
  --#{$namespace}-c-#{'' + $contextColorName}_hsl: var(
    --#{$namespace}-c-#{"" + $colorName}_hsl
  );
}

@mixin setForegroundColor($colorName) {
  @include setContextColor("foreground", $colorName);
}
@mixin setBackgroundColor($colorName) {
  @include setContextColor("background", $colorName);
}
@mixin setPrimaryColor($colorName) {
  @include setContextColor("primary", $colorName);
}
@mixin setSecondaryColor($colorName) {
  @include setContextColor("secondary", $colorName);
}

@import "../../styles/base";

.StudioModeSwitcher {
  padding: 0;
  border-bottom: 1px solid color("primary");
  .BaseSelector {
    height: 3em;
    display: block;
    margin-bottom: -1px;
    border-radius: 0;
  }
  .BaseSelectorInner {
    height: 3em;
    border-radius: 0;
  }
  .BaseSelectorInlineOption {
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    line-height: 1;
    font-weight: 800;
  }
}

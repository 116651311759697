@import "../../styles/base";

.GameExporterUI {
  .BaseButton {
    // display: flex;
    margin-right: 2px;
  }
  .StudioSidebarUIPanelContent {
    > * {
      + * {
        margin-top: 1em;
      }
    }
  }
  .BaseButton {
    display: flex;
    width: 100%;
    + * {
      margin-top: 3px;
    }
  }
  hr {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    background-color: transparent;
    height: 0;
    border: none;
    border-top: 1px dashed color("foreground", 0.1);
  }
}

@keyframes exporterStatusExporting {
  from {
    background-color: color("red", 0.5);
  }
  to {
    background-color: color("red", 0.2);
  }
}
.GameImageExporterStatusDisplay {
  background-color: color("neutral", 0.5);
  padding: 0.5em;
  border-radius: 3px;
  [data-is-exporting="true"] & {
    animation: exporterStatusExporting 0.5s infinite linear alternate;
  }
}

.GameExportButtonList {
}

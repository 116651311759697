.GameImportJsonButton {
  position: relative;
  form {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    opacity: 0;
  }
}

* {
  box-sizing: border-box;
  // outline: 1px solid cyan;
}

:root {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  background-color: color("background");
  color: color("foreground");
  font-family: font(ui);
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --safeMarginLeft: #{size(md)};
  --safeMarginRight: #{size(md)};
  --vw: 100vw;
  --vh: 100vh;
  &[data-orientation="landscape"] {
    --safeMarginLeft: calc(0.5em + env(safe-area-inset-left));
    --safeMarginRight: calc(0.5em + env(safe-area-inset-right));
  }
  &.no-scroll {
    overflow: hidden;
    width: var(--AppWidth);
    height: var(--AppHeight);
  }
  &.no-select {
    user-select: none;
  }
}

body {
  font-size: 1.2rem;
  line-height: 1.19;
  color: color("foreground");
  // [lang^="en"] & {
  //   font-size: 1.2rem;
  // }
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

code {
  font-family: font(monospace);
  font-feature-settings: "zero" 1;
  font-size: 90%;
  font-weight: 500;
  word-wrap: break-word;
}

a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: font(heading);
  margin: 0;
  font-weight: 700;
}
p {
  margin: 0;
}

::selection {
  // background-color: var(--SelectionColor);
}

:focus {
  // outline-color: color('primary');
  outline: none;
}

/* Styling React Error Screen */
body {
  iframe:last-child {
    background-color: rgb(53, 53, 53);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.EmbeddableChunkLoadErrorNotice {
  margin: 4.8rem size(lg);
  strong {
    font-size: 1.8rem;
  }
}

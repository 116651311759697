@import "../../styles/base";

.PieceGraphic {
  &[data-visibility="visible"] {
    .PieceGraphicInner {
      // opacity: 1;
      // transition: opacity .1s;
    }
  }
  &[data-visibility="hidden"] {
    pointer-events: none;
    // .PieceGraphicInner {
    // [data-piece-movement-animation="off"] &,
    // [data-is-exporting="true"] & {
    //   opacity: 0;
    // }
    // transition: opacity .15s .1s;
    // [data-is-auto-playing="true"] & {
    //   transition: opacity .15s .45s;
    // }
    // }
  }
}

.PieceGraphicHotspot {
  [data-has-selected-piece="true"][data-mode="edit"] & {
    &:hover {
      fill: color("gold", 0.2);
    }
  }
}
.PieceGraphicHighlighterCircle {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s;
  &.--visible {
    opacity: 1;
    pointer-events: auto;
  }
}

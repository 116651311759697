@import "../../../styles/base";

.BaseSpacer {
  pointer-events: none;
  display: block;
  &.inline {
    display: inline-block;
  }
  &:before {
    content: "";
    display: block;
    width: inherit;
    padding-top: 100%;
  }
  &.xs {
    width: size(xs);
    height: size(xs);
  }
  &.sm {
    width: size(sm);
    height: size(sm);
  }
  &.md {
    width: size(md);
    height: size(md);
  }
  &.ml {
    width: size(ml);
    height: size(ml);
  }
  &.lg {
    width: size(lg);
    height: size(lg);
  }
  &.xl {
    width: size(xl);
    height: size(xl);
  }
}

@import "../../styles/base";

.GameEditorUI {
}

.GameEditorNthRoundCounter {
  font-weight: 700;
  font-size: 1.8rem;
  + * {
    margin-top: 0.5em;
  }
}

.GameEditorCurrentMoveDisplay {
  background-color: color("gold", 0.2);
  padding: 1em;
}

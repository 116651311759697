@import "../../../styles/base";

.BaseSelector {
  @extend %colorCodedStates;
  &.secondary {
    @include setPrimaryColor("secondary");
  }
  &.disabled,
  &.readonly {
    pointer-events: none;
  }
}

.BaseSelectorInline {
  display: block;
  overflow: hidden;
  border-radius: 0.3em;
}
.BaseSelectorInlineInner {
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-radius: 0.3em;
  user-select: none;
  // border: .5px solid color('foreground', .1);
  border: none;
  overflow: auto;
  white-space: nowrap;
  background-color: color("foreground", 0.05);
  margin: -1px;
  @extend %hideScrollbars;
  .BaseSelector.disabled & {
    background-color: color("foreground", 0.1);
  }
  @media #{$desktop} {
    height: 2.25em;
  }
  div {
    flex: 1 1 auto;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3em 0.5em;
    cursor: pointer;
    line-height: 1;
    .BaseIcon {
      + * {
        margin-left: 0.38em;
      }
    }
    border: 0.5px solid color("background");
    &.selected {
      background-color: color("primary");
      border-color: color("background", 0.5);
      color: contrastColor("primary");
    }
    .BaseSelector.disabled & {
      color: color("foreground", 0.62);
      &.selected {
        background-color: color("primary");
        color: contrastColor("primary", 0.62);
      }
    }
  }
}

.BaseSelectorSystem {
  .BaseSelectorInner {
    user-select: none;
    position: relative;
    select {
      display: block;
      padding-right: 2em;
    }
  }
  .BaseIcon {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    pointer-events: none;
    opacity: 0.62;
  }
}
.BaseSelector.disabled {
  .BaseIcon {
    opacity: 0.38;
  }
}
.BaseSelectorSelect {
  @extend %defaultInput;
  appearance: none;
  width: 100%;
  .inverted & {
    border-color: color("white", 0.1);
    background-color: color("white", 0.1);
    &:hover {
      background-color: color("white", 0.15);
    }
  }
  .inverted[class*="state-"] & {
    border-color: color("primary", 0.75);
    background-color: color("primary", 0.19);
    &:hover {
      background-color: color("primary", 0.38);
    }
  }
}

.BaseSelectorCheckboxGroup {
  &.checkboxGroupDirectionRow {
    margin-left: -0.38em;
    margin-right: -0.38em;
    .BaseSelectorInner {
      display: flex;
      flex-wrap: wrap;
    }
    .BaseCheckbox {
      margin-right: 0.5em;
    }
    .BaseToggleInner {
      margin-left: 0;
      margin-right: 0;
    }
    .BaseToggleInner {
      // padding-right: 0;
    }
  }
}

.BaseSelectInfoAfterInputField {
  margin-top: 0.5em;
  color: color("foreground", 0.62);
  font-style: italic;
}

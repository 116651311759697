@import "../../styles/base";

@keyframes LoadingIndicatorEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes LoadingIndicatorDotRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.LoadingIndicator {
  opacity: 0;
  animation: LoadingIndicatorEnter 0.19s forwards;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  position: relative;
  span {
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: LoadingIndicatorDotRotate 0.618s infinite linear;
    position: absolute;
    &:nth-child(1) {
      top: 0;
      left: 0;
      background-color: color("inkBlue");
      transform-origin: bottom right;
    }
    &:nth-child(2) {
      right: 0;
      bottom: 0;
      background-color: color("red");
      transform-origin: top left;
    }
  }
}

@import "../../styles/base";

.StudioTitleBar {
  display: flex;
  border-bottom: 1px solid var(--BorderColor);
  background-color: color("background");
  padding: 1em;
  position: sticky;
  top: 0;
}

.StudioTitleBar__StartSlot {
  order: 1;
  flex: 1 1 33%;
  display: flex;
  align-items: center;
  > * {
    + * {
      margin-left: 0.5em;
    }
  }
}
.StudioTitleBar__CenterSlot {
  order: 2;
  display: flex;
  align-items: center;
  .ZXLogo {
    font-size: 1.6rem;
  }
  h1 {
    font-size: 1.8rem;
    [lang*="zh"] & {
      letter-spacing: 0.38em;
    }
  }
}
.StudioTitleBar__EndSlot {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 33%;
  order: 3;
}

@import "../../styles/base";

.Studio {
  display: grid;
  width: var(--vw);
  max-width: var(--vw);
  height: calc(var(--vh) - var(--StudioTitleBarHeight));
  grid-template-columns: 35rem minmax(0, 1fr) 35rem;
  grid-template-rows: minmax(0, 1fr);
  @media #{$desktop-md} {
    grid-template-columns: 40rem minmax(0, 1fr) 40rem;
  }
}

.StudioHeader {
  border-right: 1px solid var(--BorderColor);
}

.StudioContentArea {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: size(md);
  .Board {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 80vh;
    position: sticky;
    top: size(xl);
  }
}

.StudioContentAreaBackgroundHotSpot {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

.StudioContentAreaFigure {
  position: relative;
}

.StudioAside {
  border-left: 1px solid var(--BorderColor);
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}

.StudioHeader,
.StudioAsideInner {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  .ShadedBlockInner {
    padding: 0.5em;
  }
}

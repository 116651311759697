@import "../../../styles/base";

.BaseLabel {
  @extend %label;
  display: flex;
  opacity: 0.5;
  &.inline {
    display: inline-flex;
    margin-bottom: 0;
    margin-right: 0.19em;
  }
  .BaseInputRequiredMarker,
  .BaseInputOptionalMarker {
    font-weight: 400;
    opacity: 0.62;
    margin-left: 0.5em;
  }
}
.BaseLabelStartSlot {
  flex: 1 1 auto;
}
.BaseLabelEndSlot {
  flex: 0 0 auto;
  text-align: right;
}

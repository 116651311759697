@import "../../styles/base";

.GameMetaInfoEditor {
  h1 {
    font-weight: 300;
    font-family: font(genwan);
    .BaseInputContentEditable {
      border-bottom: 1px solid color("primary");
    }
  }
  table {
    width: 100%;
  }
  th {
    text-align: left;
    padding-left: 0;
    padding-right: 0.5em;
    font-weight: 400;
  }
  .BaseInput {
    width: 100%;
  }
}
